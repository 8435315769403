<template>
  <div class="error-page">
    <div class="w-100">
      <img width="100%" :src="require('@/assets/images/global/500.png')" alt="" />
      <div class="text-center  mt-4" >
        <span class="font-22 font-500 black--text global.ops global.error500"
          ><span class="primary--text">{{translate['global.ops']}}</span> {{translate['global.error500']}}</span
        >
        <p class="font-18 font-500 black--text global.error500Desc">
          {{translate["global.error500Desc"]}}
        </p>
        <div class="d-flex align-center mt-4 justify-center">
          <v-btn
            color="primary"
            dark
            class="radius-5 mx-2"
            width="160px"
            height="42px"
            :elevation="0"
            @click="$router.push('/')"
          >
            <span class="font-16 font-500 white--text global.goToHomepage" >{{
              translate["global.goToHomepage"]
            }}</span>
          </v-btn>
          <v-btn
            color="primary"
            dark
            class="radius-5 mx-2"
            width="90px"
            height="42px"
            :elevation="0"
            outlined
            @click="$router.go(-1)"
          >
            <span class="font-16 font-500 global.back">{{
              translate["global.back"]
            }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    metaInfo() {
    return {
      title: this.translate["meta.title.serverError"],
      bodyAttrs: {
        tabIndex: 0,
      },
      meta: [
        {
          name: "description",
          content: this.translate["meta.description.serverErrorContent"],
          vmid: this.translate["meta.description.serverErrorVmid"],
        },
      ],
    };
  },
  computed:{
    ...mapGetters(['translate'])
  }
};
</script>

<style lang="scss">
.error-page {
  padding: 0px 20px;
  max-width: 600px;
  margin: auto;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
